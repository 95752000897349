import React from "react";

import Blog from "../homes/blog";

import Home from "../homes/Home";

import Pack  from "../homes/Pack";

import Service from "../services/service";
import Expertice from "../homes/Expertise";
const HomePage =()=>{
    return(
        <>
        <Home/> 
      
        <Expertice/>
       
        <Blog/>
        <Service/>
       <Pack/>
 
      
        </>
    )
}
export default HomePage;