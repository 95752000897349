import { Icon } from '@mui/material';
import React from 'react'
import {Link } from "react-router-dom"
import { FloatingWhatsApp } from 'react-floating-whatsapp'

const Footer =()=> {
    return(<>
<footer>
    <div className='container grid'>
        <div className='box'data-aos='zoom-in'>
            <img src="./images/logobb.png" alt="" data-aos='zoom-in' style={{width:"180px" , height:"45px"}}/>
            <p>Une Start Up Tunisienne basée à Sfax créée en Février 2019 spécialisée dans le développement de solutions informatiques. </p>
        </div>
        <div className='box box3 marginLeft' data-aos='zoom-in'>
            <h2>Liens utiles</h2>
            <ul>
                <li><Link className="lien" to='/'>Accueil</Link></li>
                <li><Link  className="lien" to='/About'>A propos</Link></li>
                <li><Link className="lien" to='/blog'>Services</Link></li>
                <li><Link className="lien" to='/Pack'>Nos Packs</Link></li>
                <li><Link className="lien" to='/Contact'>Contact </Link></li>
           
            </ul>
        </div>
      
        <div className='box  box2' data-aos='zoom-in'>
            <h2>Contactez-nous</h2>
            <div className='flex-container '>
            <div className='flex' >
            <img src ="./images/F1.png" alt="" className="imageF"/>
             <p>Télephone : <a href="tel:+216 74 433 020">+216 74 433 020</a>
           
            </p>
</div>
<div className='flex' >
            <img src ="./images/F2.png" alt="" className="imageF"/>
            <p>Email : <a href="https://mail.google.com/mail/u/0/#inbox"> gm@polysoftco.tn</a></p></div>
            <div className='flex' >
            <img src ="./images/F3.png" alt="" className="imageF"/>
            <p>Adresse :  
            <a href="https://www.google.com/maps/place/POLYSOFT/@34.753891,10.758659,19z/data=!4m6!3m5!1s0x1301d3fcbdf5f629:0x2fe90eeb77a94e4b!8m2!3d34.753796!4d10.7584861!16s%2Fg%2F11hnpsh09s?entry=ttu">
                 Rte de Teniour Km 1,5 / Sfax, Tunisia, 3000</a></p>
                </div>
                </div></div>



                {/* <FloatingWhatsApp phoneNumber="+21653416577" accountName='zaineb' chatMessage='hello' notification='fase'/> */}
<a aria-label="Chat on WhatsApp" href="https://wa.me/+21653416577" >
   <img alt="Chat on WhatsApp"   src="./images/whatsapp.png"
    style={{  position: "fixed",
    width: "60px",
    height: "60px",
    bottom: "40px",
    right: "40px", 
    //left: "40px", 
    color: "#FFF",
    display:"block"
  }}
 />
  </a>
    </div>
    <div className='logal container'>
        <label htmlFor=''>© Copyright 2023 by GEDPLUS</label>
    </div>
</footer>
    </>)
}
export default Footer;