const Head =() =>{
return(
    <div className="head flex-container">
    
 </div>


)


}
export default Head